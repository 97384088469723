export default {
  // Main app layout
  layout: {
    // App header
    header: {
      logo: {
        alt: "Playmore Logo",
      },
    },
    // App footer
    footer: {
      provider: {
        text: "Reservation system provided by",
        previousName: "(formerly Reservise.com)",
      },
      legal: {
        // Uses common texts from common.legal
        terms: {
          text: "By using the system, you accept",
          // Link uses common.legal.terms
        },
        privacy: {
          text: "and",
          // Link uses common.legal.privacy
        },
      },
      // Copyright uses common.legal.copyright
    },
  },
  // Global messages
  messages: {
    // Uses common messages from common.status
    loading: "{resource}", // e.g., "Loading data..."
    error: "{message}", // e.g., "An error occurred while loading"
    success: "{message}", // e.g., "Operation completed successfully"
  },
  // Form validation
  validation: {
    // Uses common messages from common.validation
  },
  // Actions
  actions: {
    // Uses common actions from common.actions
  },
};
