export default {
  // Login process
  login: {
    title: {
      phone: "Login",
      code: "Enter code",
    },
    subtitle: {
      phone: "Enter your phone number",
      code: "Enter the code received via SMS",
    },
    form: {
      // Uses common fields from common.fields
      phone: {
        placeholder: "123 456 789",
      },
      code: {
        label: "Verification code",
        placeholder: "Enter 4-digit code",
      },
      buttons: {
        phone: "Send code",
        code: "Verify",
        loading: "Please wait...",
      },
    },
    messages: {
      codeSent: "Verification code has been sent to your phone number.",
    },
  },
  // Registration process
  registration: {
    complete: {
      title: "Complete registration",
      subtitle: "Complete your details to fully access the platform features",
      form: {
        // Uses common fields from common.fields
        buttons: {
          submit: "Save and continue",
        },
      },
      welcome: {
        title: "Welcome to Playmore!",
        description:
          "Complete your profile to fully access platform features. Your data will help us better tailor our offer to your needs.",
        features: {
          notifications: {
            title: "Reservation notifications",
            description:
              "Receive confirmations and reminders to your email address",
          },
          history: {
            title: "Activity history",
            description: "Track your reservations and payments in one place",
          },
        },
      },
    },
    confirm: {
      title: "Confirm email address",
      loading: "Confirming your email address...",
      success:
        "Email address has been successfully confirmed. Redirecting you to the homepage...",
    },
  },
  // Legal information
  legal: {
    consent:
      'By clicking "Send code" you agree to Playmore (formerly Reservise) terms of service, and acknowledge that you have read and accept',
    gdpr: {
      button: "GDPR",
      title: "According to GDPR, you have the right to:",
      rights: [
        "access your personal data",
        "rectify (correct) your data",
        "delete your data",
        "restrict data processing",
        "data portability",
        "object to processing",
        "withdraw consent at any time",
        "lodge a complaint with a supervisory authority",
      ],
      info: "Your personal data is controlled by Reservise Sp. z o.o. ul. Lindleya 16/301 • 02-013 Warsaw • KRS: 0000522272. Data is processed for the purpose of providing sports facility reservation services and for marketing purposes, based on your consent or the legitimate interest of the controller.",
    },
  },
  // Welcome section
  welcome: {
    title: "Reservise is now Playmore!",
    description:
      "Join a community of over 500,000 players and discover a new dimension of sports facility reservations. Everything you need in one place.",
    features: {
      reservations: {
        title: "Easy reservations",
        description: "Book courts, classes and sports facilities in seconds",
      },
      venues: {
        title: "Largest venue database",
        description: "Hundreds of sports facilities across Poland",
      },
      trainers: {
        title: "Professional trainers",
        description:
          "Find a trainer and sign up for individual or group classes",
      },
      passes: {
        title: "Convenient passes",
        description: "Buy and manage passes in one place",
      },
      account: {
        title: "Everything in one account",
        description:
          "Manage reservations, passes and classes from a single application",
      },
    },
  },
};
