import account from "./en/account";
import app from "./en/app";
import carnets from "./en/carnets";
import common from "./en/common";
import errors from "./en/errors";
import login from "./en/login";
import meta from "./en/meta";
import nav from "./en/nav";
import reservations from "./en/reservations";
import trainerProfile from "./en/trainerProfile";
import users from "./en/users";

export default {
  account,
  app,
  carnets,
  common,
  errors,
  login,
  meta,
  nav,
  reservations,
  trainerProfile,
  users,
};
