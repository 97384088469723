import { ref, onMounted, onUnmounted } from "vue";

export function useDarkMode() {
  const isDark = ref(false);

  // Sprawdź czy przeglądarka preferuje tryb ciemny
  const prefersDark = () => {
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  };

  // Ustaw tryb ciemny
  const setDarkMode = (value) => {
    isDark.value = value;
    if (value) {
      document.documentElement.classList.add("dark");
      document.documentElement.classList.remove("light");
      localStorage.theme = "dark";
    } else {
      document.documentElement.classList.remove("dark");
      document.documentElement.classList.add("light");
      localStorage.theme = "light";
    }
  };

  // Przełącz tryb ciemny
  const toggleDarkMode = () => {
    setDarkMode(!isDark.value);
  };

  // Ustaw tryb systemowy
  const setSystemMode = () => {
    localStorage.removeItem("theme");
    document.documentElement.classList.remove("light", "dark");
    setDarkMode(prefersDark());
  };

  // Handler dla zmian preferencji systemowych
  const handleSystemPreferenceChange = (e) => {
    if (!localStorage.theme) {
      setDarkMode(e.matches);
    }
  };

  // Nasłuchuj zmian preferencji systemowych
  const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

  onMounted(() => {
    // Inicjalizacja trybu
    const isDarkMode =
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) && prefersDark());

    setDarkMode(isDarkMode);

    // Dodaj listener dla zmian preferencji systemowych
    darkModeMediaQuery.addEventListener("change", handleSystemPreferenceChange);
  });

  // Cleanup przy odmontowaniu komponentu
  onUnmounted(() => {
    darkModeMediaQuery.removeEventListener(
      "change",
      handleSystemPreferenceChange
    );
  });

  return {
    isDark,
    toggleDarkMode,
    setDarkMode,
    setSystemMode,
  };
}
