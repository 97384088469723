export default {
  404: {
    title: "Аут! М'яч поза кортом!",
    description:
      "Схоже, ця сторінка взяла перерву. Може, зіграємо на іншому корті?",
    button: "Повернутися на головний корт",
    easterEgg: "Вау! Чудовий розіграш! Хочеш стати нашим тренером? 🎾",
  },
  500: {
    title: "Упс! Сервер потребує технічної перерви",
    description:
      "Наш (тенісний) сервер потребує невеликого ремонту. Наша магічна ракетка вже працює над цим!",
    button: "Спробувати знову",
    easterEgg:
      "Бачу, ти любиш ремонтувати! Хочеш приєднатися до нашої команди? 🛠️",
  },
};
