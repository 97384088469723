import { createRouter, createWebHistory } from "vue-router";
import { generateRoutes } from "./auto-import";
import store from "../store";

const routeConfigs = [
  {
    path: "/",
    redirect: "/your-account",
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: "users/Login",
    meta: { title: "Logowanie | Playmore — Player" },
  },
  {
    path: "/complete-registration",
    name: "CompleteRegistration",
    component: "users/CompleteRegistration",
    meta: {
      requiresAuth: true,
      title: "Dokończ rejestrację | Playmore — Player",
    },
  },
  {
    path: "/your-account",
    name: "YourAccount",
    component: "users/Account",
    meta: { requiresAuth: true, title: "Twoje konto | Playmore — Player" },
  },
  {
    path: "/dump-account",
    name: "DumpAccount",
    component: "users/DumpAccount",
    meta: { requiresAuth: true, title: "Usuń konto | Playmore — Player" },
  },
  {
    path: "/confirm-email",
    name: "ConfirmEmail",
    component: "users/ConfirmEmail",
    meta: { requiresAuth: false, title: "Potwierdź email | Playmore — Player" },
  },
  {
    path: "/trainer-profile",
    name: "TrainerProfileOverview",
    component: "trainerProfile/Overview",
    meta: { requiresAuth: true, title: "Profil trenera | Playmore — Player" },
  },
  {
    path: "/trainer-schedule",
    name: "TrainerProfileSchedule",
    component: "trainerProfile/WorkingHours",
    meta: { requiresAuth: true, title: "Godziny pracy | Playmore — Player" },
  },
  {
    path: "/trainer-reservations",
    name: "TrainerProfileReservations",
    component: "trainerProfile/Reservations",
    meta: {
      requiresAuth: true,
      title: "Rezerwacje trenera | Playmore — Player",
    },
  },
  {
    path: "/reservation/:venueId",
    component: "reservations/reservations",
    meta: { requiresAuth: true, title: "Rezerwacja | Playmore — Player" },
    children: [
      {
        path: "",
        redirect: (to) => ({
          name: "ReservationCourts",
          params: { venueId: to.params.venueId },
        }),
      },
      {
        path: "courts",
        name: "ReservationCourts",
        component: "reservations/ReservationCourts",
      },
      {
        path: "classes",
        name: "ReservationClasses",
        component: "reservations/ReservationClasses",
      },
      {
        path: "trainers",
        name: "ReservationTrainers",
        component: "reservations/ReservationTrainers",
      },
      {
        path: "carnets",
        name: "ReservationCarnets",
        component: "reservations/ReservationCarnets",
      },
    ],
  },
  {
    path: "/trainers/:venueId",
    name: "Trainers",
    component: "trainers/trainers",
    meta: { requiresAuth: false, title: "Trenerzy | Playmore — Player" },
  },
  {
    path: "/carnets/:venueId",
    name: "Carnets",
    component: "carnets/carnets",
    meta: { requiresAuth: false, title: "Karnety | Playmore — Player" },
  },
  {
    path: "/my-carnets",
    name: "UserCarnets",
    component: "users/carnets/UserCarnets",
    meta: { requiresAuth: true, title: "Moje karnety | Playmore — Player" },
  },
  {
    path: "/my-reservations",
    name: "UserReservations",
    component: "users/reservations/UserReservations",
    meta: { requiresAuth: true, title: "Moje rezerwacje | Playmore — Player" },
  },
  {
    path: "/reservation/:venueId/details/:reservationId",
    name: "ReservationDetails",
    component: "users/reservations/ReservationDetails",
    meta: {
      requiresAuth: true,
      title: "Szczegóły rezerwacji | Playmore — Player",
    },
  },
  {
    path: "/my-reservations/recurring/:parentId",
    name: "RecurringReservationDetails",
    component: "users/reservations/RecurringReservationDetails",
    meta: {
      requiresAuth: true,
      title: "Szczegóły rezerwacji stałej | Playmore — Player",
    },
  },
  // Strony błędów
  {
    path: "/500",
    name: "Error500",
    component: "error-pages/Error500",
    meta: {
      requiresAuth: false,
      title: "Błąd serwera | Playmore — Player",
      error: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error404",
    component: "error-pages/Error404",
    meta: {
      requiresAuth: false,
      title: "Strona nie znaleziona | Playmore — Player",
      error: true,
    },
  },
];

const routes = generateRoutes(routeConfigs);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// Navigation guard
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = store.getters["user/isAuthenticated"];
  const isErrorPage = to.matched.some((record) => record.meta.error);

  // Ustaw tytuł strony
  document.title = to.meta.title || "Playmore — Player";

  // Pozwól na dostęp do stron błędów bez autoryzacji
  if (isErrorPage) {
    next();
  } else if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else {
    next();
  }
});

// Obsługa błędów routingu
router.onError((error) => {
  console.error("Błąd routingu:", error);
  router.push({ name: "Error500" });
});

export default router;
