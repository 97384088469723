export default {
  noProfile: {
    title: "Немає активного профілю тренера",
    message: "У вас ще немає профілю тренера в жодному закладі.",
    instruction:
      "Щоб активувати профіль тренера, зверніться до адміністрації обраного клубу. Після активації ви зможете керувати своєю доступністю та приймати бронювання.",
  },
  status: {
    title: "Статус профілю",
    pendingMessage:
      "Ваш профіль очікує підтвердження закладом. Після підтвердження ви зможете керувати своєю доступністю.",
    acceptedMessage: "Ваш профіль активний і підтверджений закладом.",
    visibility: {
      label: "Онлайн видимість",
      visible: "Видимий",
      hidden: "Прихований",
    },
    comment: "Коментар",
  },
  workingHours: {
    title: "Робочі години",
    save: "Зберегти години",
    saving: "Збереження...",
    success: "Години доступності збережено",
    error: "Не вдалося зберегти години доступності",
  },
  reservations: {
    views: {
      calendar: "Календар",
      list: "Список",
    },
    filters: {
      upcoming: "Майбутні",
      past: "Минулі",
    },
    cancellation: {
      deadline: "Термін скасування",
    },
    list: {
      noReservations: "Немає бронювань",
      client: "Клієнт",
      date: "Дата",
      time: "Час",
      venue: "Заклад",
      status: "Статус",
      actions: "Дії",
    },
    calendar: {
      previous: "Попередній",
      next: "Наступний",
      today: "Сьогодні",
      month: "Місяць",
      week: "Тиждень",
      day: "День",
    },
  },
  navigation: {
    overview: {
      label: "Огляд",
      icon: "👨‍🏫",
    },
    schedule: {
      label: "Робочі години",
      icon: "📅",
    },
    reservations: {
      label: "Мої бронювання",
      icon: "📋",
    },
  },
};
