export default {
  noProfile: {
    title: "Brak aktywnego profilu trenera",
    message: "Nie posiadasz jeszcze profilu trenera w żadnym obiekcie.",
    instruction:
      "Aby aktywować profil trenera, skontaktuj się z recepcją wybranego klubu. Po aktywacji profilu będziesz mógł zarządzać swoją dostępnością i przyjmować rezerwacje.",
  },
  status: {
    title: "Status profilu",
    pendingMessage:
      "Twój profil oczekuje na zatwierdzenie przez obiekt. Po zatwierdzeniu będziesz mógł zarządzać swoją dostępnością.",
    acceptedMessage: "Twój profil jest aktywny i zatwierdzony przez obiekt.",
    visibility: {
      label: "Widoczność online",
      visible: "Widoczny",
      hidden: "Ukryty",
    },
    comment: "Komentarz",
  },
  workingHours: {
    title: "Godziny pracy",
    save: "Zapisz godziny",
    saving: "Zapisywanie...",
    success: "Godziny dostępności zostały zapisane",
    error: "Nie udało się zapisać godzin dostępności",
  },
  reservations: {
    views: {
      calendar: "Kalendarz",
      list: "Lista",
    },
    filters: {
      upcoming: "Nadchodzące",
      past: "Rozegrane",
    },
    cancellation: {
      deadline: "Termin anulowania",
    },
    list: {
      noReservations: "Brak rezerwacji",
      client: "Klient",
      date: "Data",
      time: "Godzina",
      venue: "Obiekt",
      status: "Status",
      actions: "Akcje",
    },
    calendar: {
      previous: "Poprzednie",
      next: "Następne",
      today: "Dziś",
      month: "Miesiąc",
      week: "Tydzień",
      day: "Dzień",
    },
  },
  navigation: {
    overview: {
      label: "Przegląd",
      icon: "👨‍🏫",
    },
    schedule: {
      label: "Godziny pracy",
      icon: "📅",
    },
    reservations: {
      label: "Moje rezerwacje",
      icon: "📋",
    },
  },
};
