export default {
  // Login process
  login: {
    title: {
      phone: "Вхід",
      code: "Введіть код",
    },
    subtitle: {
      phone: "Введіть свій номер телефону",
      code: "Введіть код, отриманий через SMS",
    },
    form: {
      phone: {
        placeholder: "123 456 789",
      },
      code: {
        label: "Код підтвердження",
        placeholder: "Введіть 4-значний код",
      },
      buttons: {
        phone: "Надіслати код",
        code: "Підтвердити",
        loading: "Зачекайте...",
      },
    },
    messages: {
      codeSent: "Код підтвердження надіслано на ваш номер телефону.",
    },
  },
  // Registration process
  registration: {
    complete: {
      title: "Завершення реєстрації",
      subtitle: "Заповніть свої дані для повного доступу до функцій платформи",
      form: {
        buttons: {
          submit: "Зберегти та продовжити",
        },
      },
      welcome: {
        title: "Ласкаво просимо до Playmore!",
        description:
          "Заповніть свій профіль для повного доступу до функцій платформи. Ваші дані допоможуть нам краще адаптувати нашу пропозицію до ваших потреб.",
        features: {
          notifications: {
            title: "Сповіщення про бронювання",
            description:
              "Отримуйте підтвердження та нагадування на вашу електронну пошту",
          },
          history: {
            title: "Історія активності",
            description:
              "Відстежуйте свої бронювання та платежі в одному місці",
          },
        },
      },
    },
    confirm: {
      title: "Підтвердження електронної пошти",
      loading: "Підтвердження вашої електронної пошти...",
      success:
        "Електронну адресу успішно підтверджено. Перенаправляємо вас на головну сторінку...",
    },
  },
  // Legal information
  legal: {
    consent:
      'Натискаючи "Надіслати код", ви погоджуєтесь з умовами надання послуг Playmore (раніше Reservise) та підтверджуєте, що прочитали та приймаєте',
    gdpr: {
      button: "GDPR",
      title: "Згідно з GDPR, ви маєте право на:",
      rights: [
        "доступ до ваших персональних даних",
        "виправлення (корекцію) ваших даних",
        "видалення ваших даних",
        "обмеження обробки даних",
        "перенесення даних",
        "заперечення проти обробки",
        "відкликання згоди в будь-який час",
        "подання скарги до наглядового органу",
      ],
      info: "Ваші персональні дані контролюються Reservise Sp. z o.o. ul. Lindleya 16/301 • 02-013 Варшава • KRS: 0000522272. Дані обробляються з метою надання послуг бронювання спортивних об'єктів та в маркетингових цілях на підставі вашої згоди або законного інтересу контролера.",
    },
  },
  // Welcome section
  welcome: {
    title: "Reservise тепер Playmore!",
    description:
      "Приєднуйтесь до спільноти понад 500 000 гравців та відкрийте новий вимір бронювання спортивних об'єктів. Все, що вам потрібно, в одному місці.",
    features: {
      reservations: {
        title: "Просте бронювання",
        description: "Бронюйте корти, заняття та спортивні об'єкти за секунди",
      },
      venues: {
        title: "Найбільша база закладів",
        description: "Сотні спортивних об'єктів по всій Польщі",
      },
      trainers: {
        title: "Професійні тренери",
        description:
          "Знайдіть тренера та запишіться на індивідуальні або групові заняття",
      },
      passes: {
        title: "Зручні абонементи",
        description: "Купуйте та керуйте абонементами в одному місці",
      },
      account: {
        title: "Все в одному обліковому записі",
        description:
          "Керуйте бронюваннями, абонементами та заняттями з одного додатку",
      },
    },
  },
};
