export default (api) => ({
  async getAppSports() {
    const response = await api.get("/app/sport_type");
    return response.data;
  },

  async shortURL(token, userid) {
    const response = await api.post("/app/url_shortener", {
      user_id: userid,
      short_url_token: token,
    });
    return response.data;
  },
});
