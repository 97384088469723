export default {
  greeting: "Cześć, {name}!",
  email: {
    unverified: {
      title: "Email niezweryfikowany",
      message: "Potwierdź swój adres email, aby w pełni korzystać z konta",
    },
    verified: {
      title: "Email zweryfikowany",
      message: "Twój adres email został potwierdzony",
    },
  },
  sections: {
    venues: {
      title: "Twoje obiekty",
      empty: "Nie masz jeszcze żadnych obiektów",
    },
    data: {
      title: "Dane konta",
      phone: {
        note: "Numer telefonu nie może zostać zmieniony",
      },
    },
    levels: {
      title: "Poziomy gry",
      description:
        "Zdefiniuj swój poziom w każdym sporcie, aby ułatwić recepcji i innym zawodnikom dobieranie odpowiednich przeciwników. Pomoże to w organizacji lepszych meczów i bardziej wyrównanych rozgrywek.",
      lastUpdate: "Ostatnia aktualizacja: {date}",
      levels: {
        0: "Początkujący",
        1: "Podstawowy",
        2: "Średnio-zaawansowany",
        3: "Zaawansowany",
        4: "Bardzo zaawansowany",
        5: "Semi-pro",
        6: "Profesjonalista",
      },
    },
  },
  form: {
    firstName: "Imię",
    lastName: "Nazwisko",
    email: "Email",
    phone: "Numer telefonu",
    buttons: {
      save: "Zapisz zmiany",
      saving: "Zapisywanie...",
    },
  },
  // Nawigacja profilu
  navigation: {
    venues: {
      label: "Twoje obiekty",
      icon: "🏢",
    },
    data: {
      label: "Dane konta",
      icon: "👤",
    },
    levels: {
      label: "Poziomy gry",
      icon: "📊",
    },
  },
};
