export default {
  app: {
    title: "Playmore - Rezerwacje obiektów sportowych",
    description:
      "Playmore - platforma do rezerwacji obiektów sportowych, zajęć fitness i treningów personalnych",
  },
  reservations: {
    courts: {
      title: "Rezerwacja kortów | Playmore",
      description:
        "Zarezerwuj kort tenisowy, squashowy lub badmintonowy. Sprawdź dostępność i ceny w obiektach sportowych w Twojej okolicy.",
    },
    classes: {
      title: "Zajęcia fitness | Playmore",
      description:
        "Zapisz się na zajęcia fitness, jogę, pilates i inne zajęcia grupowe. Sprawdź grafik i dostępność miejsc.",
    },
    trainers: {
      title: "Trenerzy personalni | Playmore",
      description:
        "Znajdź trenera personalnego i umów się na indywidualny trening. Przeglądaj profile trenerów i ich specjalizacje.",
    },
  },
  account: {
    title: "Twój profil | Playmore",
    description:
      "Zarządzaj swoim kontem, rezerwacjami i karnetami. Sprawdź historię aktywności i nadchodzące zajęcia.",
  },
  venue: {
    title: "{name} | Playmore",
    description:
      "{name} - sprawdź dostępność obiektów sportowych, cennik i zarezerwuj termin online.",
  },
  trainer: {
    title: "{name} - Trener | Playmore",
    description:
      "Trener {name} - sprawdź dostępność, specjalizacje i umów się na trening personalny.",
  },
};
