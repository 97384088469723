export default {
  app: {
    title: "Playmore - Бронювання спортивних об'єктів",
    description:
      "Playmore - платформа для бронювання спортивних об'єктів, фітнес-занять та персональних тренувань",
  },
  reservations: {
    courts: {
      title: "Бронювання кортів | Playmore",
      description:
        "Забронюйте тенісний, сквош або бадмінтонний корт. Перевірте доступність та ціни в спортивних об'єктах у вашому районі.",
    },
    classes: {
      title: "Фітнес-заняття | Playmore",
      description:
        "Запишіться на фітнес-заняття, йогу, пілатес та інші групові заняття. Перевірте розклад та наявність місць.",
    },
    trainers: {
      title: "Персональні тренери | Playmore",
      description:
        "Знайдіть персонального тренера та запишіться на індивідуальне тренування. Перегляньте профілі тренерів та їх спеціалізації.",
    },
  },
  account: {
    title: "Ваш профіль | Playmore",
    description:
      "Керуйте своїм обліковим записом, бронюваннями та абонементами. Перевірте історію активності та майбутні заняття.",
  },
  venue: {
    title: "{name} | Playmore",
    description:
      "{name} - перевірте доступність спортивних об'єктів, ціни та забронюйте онлайн.",
  },
  trainer: {
    title: "{name} - Тренер | Playmore",
    description:
      "Тренер {name} - перевірте доступність, спеціалізації та запишіться на персональне тренування.",
  },
};
