export default {
  // Główny layout aplikacji
  layout: {
    // Nagłówek aplikacji
    header: {
      logo: {
        alt: "Playmore Logo",
      },
    },
    // Stopka aplikacji
    footer: {
      provider: {
        text: "System rezerwacji dostarcza",
        previousName: "(poprzednio Reservise.com)",
      },
      legal: {
        // Używa wspólnych tekstów z common.legal
        terms: {
          text: "Korzystając z systemu, akceptujesz",
          // Link używa common.legal.terms
        },
        privacy: {
          text: "oraz",
          // Link używa common.legal.privacy
        },
      },
      // Copyright używa common.legal.copyright
    },
  },
  // Komunikaty globalne
  messages: {
    // Używa wspólnych komunikatów z common.status
    loading: "{resource}", // np. "Ładowanie danych..."
    error: "{message}", // np. "Wystąpił błąd podczas ładowania"
    success: "{message}", // np. "Operacja zakończona pomyślnie"
  },
  // Walidacja formularzy
  validation: {
    // Używa wspólnych komunikatów z common.validation
  },
  // Akcje
  actions: {
    // Używa wspólnych akcji z common.actions
  },
};
