export default {
  404: {
    title: "Aut! Piłka poza kortem!",
    description:
      "Wygląda na to, że ta strona zrobiła sobie przerwę. Może zagrajmy na innym korcie?",
    button: "Wróć na kort główny",
    easterEgg: "Wow! Nieźle odbijasz! Może zostaniesz naszym trenerem? 🎾",
  },
  500: {
    title: "Ups! Serwer potrzebuje przerwy technicznej",
    description:
      "Nasz serwer (tenisowy) wymaga małej naprawy. Nasza magiczna rakieta już nad tym pracuje!",
    button: "Spróbuj ponownie",
    easterEgg:
      "Widzę, że lubisz naprawiać rzeczy! Może dołączysz do naszego zespołu? 🛠️",
  },
};
