export default {
  // Main app layout
  layout: {
    // App header
    header: {
      logo: {
        alt: "Логотип Playmore",
      },
    },
    // App footer
    footer: {
      provider: {
        text: "Система бронювання надається",
        previousName: "(раніше Reservise.com)",
      },
      legal: {
        // Uses common texts from common.legal
        terms: {
          text: "Використовуючи систему, ви приймаєте",
          // Link uses common.legal.terms
        },
        privacy: {
          text: "та",
          // Link uses common.legal.privacy
        },
      },
      // Copyright uses common.legal.copyright
    },
  },
  // Global messages
  messages: {
    // Uses common messages from common.status
    loading: "{resource}", // e.g., "Завантаження даних..."
    error: "{message}", // e.g., "Сталася помилка під час завантаження"
    success: "{message}", // e.g., "Операцію успішно завершено"
  },
  // Form validation
  validation: {
    // Uses common messages from common.validation
  },
  // Actions
  actions: {
    // Uses common actions from common.actions
  },
};
