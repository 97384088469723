import { createI18n } from "vue-i18n";
import pl from "./messages/pl";
import en from "./messages/en";
import ua from "./messages/ua";

// Wykryj preferowany język przeglądarki
const getBrowserLanguage = () => {
  const browserLang = navigator.language.toLowerCase().split("-")[0];
  return ["pl", "en", "ua"].includes(browserLang) ? browserLang : "pl";
};

// Pobierz zapisany język lub użyj preferencji przeglądarki
const savedLanguage = localStorage.getItem("language");
const defaultLanguage = savedLanguage || getBrowserLanguage();

// Ustaw język w HTML
document.querySelector("html").setAttribute("lang", defaultLanguage);

// Dodaj meta tagi dla SEO
const addLanguageMeta = () => {
  const head = document.head;
  const languages = ["pl", "en", "ua"];

  languages.forEach((lang) => {
    const link = document.createElement("link");
    link.rel = "alternate";
    link.hreflang = lang;
    link.href = `${window.location.origin}${window.location.pathname}?lang=${lang}`;
    head.appendChild(link);
  });
};

addLanguageMeta();

export const i18n = createI18n({
  legacy: false, // Używamy Composition API
  locale: defaultLanguage,
  fallbackLocale: "pl",
  messages: {
    pl,
    en,
    ua,
  },
  globalInjection: true,
  useScope: "global",
  // Dodaj obsługę liczby mnogiej dla każdego języka
  pluralizationRules: {
    ua: (choice, choicesLength) => {
      if (choice === 0) return 0;

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (!teen && endsWithOne) return 1;
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) return 2;

      return choicesLength < 4 ? 2 : 3;
    },
  },
  // Dodaj formatowanie dat dla każdego języka
  datetimeFormats: {
    pl: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
    },
    en: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
    },
    ua: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
    },
  },
});

// Plugin do Vue
export const i18nPlugin = {
  install: (app) => {
    app.config.globalProperties.$t = i18n.global.t;
    app.config.globalProperties.$i18n = i18n.global;

    // Dodaj metodę zmiany języka z obsługą ładowania
    app.config.globalProperties.$setLanguage = async (lang) => {
      if (["pl", "en", "ua"].includes(lang)) {
        // Dodaj klasę podczas ładowania
        document.documentElement.classList.add("language-switching");

        try {
          // Symuluj krótkie opóźnienie dla płynniejszej animacji
          await new Promise((resolve) => setTimeout(resolve, 150));

          i18n.global.locale.value = lang;
          localStorage.setItem("language", lang);
          document.querySelector("html").setAttribute("lang", lang);

          // Aktualizuj meta tagi
          const canonical = document.querySelector('link[rel="canonical"]');
          if (canonical) {
            canonical.href = `${window.location.origin}${window.location.pathname}?lang=${lang}`;
          }
        } finally {
          // Usuń klasę po zakończeniu
          document.documentElement.classList.remove("language-switching");
        }
      }
    };

    // Metoda przełączania języka
    app.config.globalProperties.$toggleLanguage = () => {
      const currentLang = i18n.global.locale.value;
      const languages = ["pl", "en", "ua"];
      const currentIndex = languages.indexOf(currentLang);
      const nextIndex = (currentIndex + 1) % languages.length;
      app.config.globalProperties.$setLanguage(languages[nextIndex]);
    };
  },
};
