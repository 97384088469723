<template>
  <div class="relative inline-block text-left" :class="$attrs.class">
    <button
      @click="isOpen = !isOpen"
      class="flex items-center space-x-2 px-3 py-1 rounded-md text-sm font-medium text-off-white hover:text-light-grey transition-colors duration-200"
      :title="t('common.switchLanguage')"
    >
      <!-- Aktualna flaga -->
      <span v-if="currentLanguage === 'PL'" class="text-lg">🇵🇱</span>
      <span v-else-if="currentLanguage === 'UA'" class="text-lg">🇺🇦</span>
      <span v-else class="text-lg">🇬🇧</span>
      <svg
        class="w-4 h-4 transition-transform duration-200"
        :class="{ 'rotate-180': isOpen }"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>

    <!-- Lista rozwijana -->
    <div
      v-if="isOpen"
      class="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
    >
      <!-- Polski -->
      <button
        @click="selectLanguage('pl')"
        class="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
        :class="{ 'bg-gray-50': currentLanguage === 'PL' }"
      >
        <span class="text-lg">🇵🇱</span>
        <span>{{ t("common.languages.polish") }}</span>
        <span
          v-if="currentLanguage === 'PL'"
          class="ml-auto text-dynamic-orange"
          >✓</span
        >
      </button>

      <!-- Angielski -->
      <button
        @click="selectLanguage('en')"
        class="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
        :class="{ 'bg-gray-50': currentLanguage === 'EN' }"
      >
        <span class="text-lg">🇬🇧</span>
        <span>{{ t("common.languages.english") }}</span>
        <span
          v-if="currentLanguage === 'EN'"
          class="ml-auto text-dynamic-orange"
          >✓</span
        >
      </button>

      <!-- Ukraiński -->
      <button
        @click="selectLanguage('ua')"
        class="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
        :class="{ 'bg-gray-50': currentLanguage === 'UA' }"
      >
        <span class="text-lg">🇺🇦</span>
        <span>{{ t("common.languages.ukrainian") }}</span>
        <span
          v-if="currentLanguage === 'UA'"
          class="ml-auto text-dynamic-orange"
          >✓</span
        >
      </button>
    </div>

    <!-- Overlay do zamykania menu -->
    <div v-if="isOpen" class="fixed inset-0 z-40" @click="isOpen = false"></div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();
const isOpen = ref(false);

const currentLanguage = computed(() => {
  return locale.value.toUpperCase();
});

const selectLanguage = (lang) => {
  locale.value = lang;
  localStorage.setItem("language", lang);
  document.querySelector("html").setAttribute("lang", lang);
  isOpen.value = false;
};

// Zamykanie menu przy kliknięciu Escape
const handleEscape = (e) => {
  if (e.key === "Escape") {
    isOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener("keydown", handleEscape);
});

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleEscape);
});
</script>
