import { useI18n } from "vue-i18n";

export function useMetaTags() {
  const { locale } = useI18n();

  const updateMetaTags = ({
    title,
    description,
    image = "/favicon.png",
    type = "website",
    url = window.location.href,
  }) => {
    // Podstawowe meta tagi
    document.title = `${title} | Playmore`;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", description);

    // Open Graph
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", title);
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", description);
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute("content", image);
    document
      .querySelector('meta[property="og:url"]')
      .setAttribute("content", url);
    document
      .querySelector('meta[property="og:type"]')
      .setAttribute("content", type);
    document
      .querySelector('meta[property="og:locale"]')
      .setAttribute(
        "content",
        locale.value === "pl"
          ? "pl_PL"
          : locale.value === "ua"
          ? "uk_UA"
          : "en_GB"
      );

    // Twitter
    document
      .querySelector('meta[name="twitter:title"]')
      .setAttribute("content", title);
    document
      .querySelector('meta[name="twitter:description"]')
      .setAttribute("content", description);
    document
      .querySelector('meta[name="twitter:image"]')
      .setAttribute("content", image);

    // Język
    document.documentElement.setAttribute(
      "lang",
      locale.value === "ua" ? "uk" : locale.value
    );
  };

  const generateSchemaOrg = ({
    type,
    name,
    description,
    image,
    url = window.location.href,
    ...additionalProps
  }) => {
    const baseSchema = {
      "@context": "https://schema.org",
      "@type": type,
      name,
      description,
      url,
      image,
      inLanguage: locale.value === "ua" ? "uk" : locale.value,
    };

    const schema = {
      ...baseSchema,
      ...additionalProps,
    };

    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.text = JSON.stringify(schema);

    // Usuń poprzedni schemat jeśli istnieje
    const existingSchema = document.querySelector(
      'script[type="application/ld+json"]'
    );
    if (existingSchema) {
      existingSchema.remove();
    }

    document.head.appendChild(script);
  };

  const generateSportsFacilitySchema = (venue) => {
    generateSchemaOrg({
      type: "SportsActivityLocation",
      name: venue.name,
      description: venue.description,
      image: venue.image,
      address: {
        "@type": "PostalAddress",
        streetAddress: venue.address.street,
        addressLocality: venue.address.city,
        postalCode: venue.address.postalCode,
        addressCountry: "PL",
      },
      telephone: venue.phone,
      openingHours: venue.openingHours,
      priceRange: venue.priceRange,
      amenityFeature: venue.amenities?.map((amenity) => ({
        "@type": "LocationFeatureSpecification",
        name: amenity,
      })),
    });
  };

  const generateSportsEventSchema = (classData) => {
    const venueName = classData._venue_sport_object?.name;

    generateSchemaOrg({
      type: "SportsEvent",
      name: classData.name,
      description: classData.description,
      startDate: new Date(classData.start_time).toISOString(),
      endDate: new Date(classData.end_time).toISOString(),
      location: {
        "@type": "SportsActivityLocation",
        name: venueName,
        address: {
          "@type": "PostalAddress",
          addressCountry: "PL",
        },
      },
      organizer: {
        "@type": "Organization",
        name: venueName,
      },
      performer: classData._trainer_venue_profile
        ? {
            "@type": "Person",
            name: `${classData._trainer_venue_profile.first_name} ${classData._trainer_venue_profile.last_name}`,
          }
        : undefined,
    });
  };

  const generatePersonSchema = (trainer, venueName) => {
    generateSchemaOrg({
      type: "Person",
      name: `${trainer.first_name} ${trainer.last_name}`,
      description: trainer.description,
      image: trainer.image,
      jobTitle: "Trainer",
      worksFor: {
        "@type": "Organization",
        name: venueName,
      },
    });
  };

  return {
    updateMetaTags,
    generateSchemaOrg,
    generateSportsFacilitySchema,
    generateSportsEventSchema,
    generatePersonSchema,
  };
}
