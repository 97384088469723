import api from "@/api";
import { useCache } from "@/composables/useCache";

// Inicjalizacja cache'u
const userCache = useCache("userProfile");

export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem("user")) || {
      id: null,
      first_name: "",
      last_name: "",
      email: "",
      is_active: false,
      phone_verified: false,
      email_verified: false,
      phone_number: "",
      marketing_consent: false,
      is_deleted: false,
      _user_venue_profiles: [],
      _user_sport_skill: [],
      _trainer_venue_profiles: [],
    },
    authToken: localStorage.getItem("authToken") || null,
  },
  mutations: {
    setUser(state, user) {
      state.user = {
        ...state.user,
        ...user,
        _trainer_venue_profiles: user._trainer_venue_profiles || [],
        _user_venue_profiles: user._user_venue_profiles || [],
        _user_sport_skill: user._user_sport_skill || [],
      };
      localStorage.setItem("user", JSON.stringify(state.user));

      // Cache user data
      if (user.id) {
        userCache.setInCache(`user-${user.id}`, user);
      }
    },
    setAuthToken(state, token) {
      state.authToken = token;
      localStorage.setItem("authToken", token);
    },
    clearAuth(state) {
      state.user = {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
        is_active: false,
        phone_verified: false,
        email_verified: false,
        phone_number: "",
        marketing_consent: false,
        is_deleted: false,
        _user_venue_profiles: [],
        _user_sport_skill: [],
        _trainer_venue_profiles: [],
      };
      state.authToken = null;
      localStorage.removeItem("user");
      localStorage.removeItem("authToken");

      // Clear user cache
      userCache.clearCache();
    },
  },
  actions: {
    async initiateOtpLogin(_, phoneNumber) {
      await api.users.initiateOtpLogin({
        source: "web",
        phone_number: phoneNumber,
      });
    },
    async verifyOtpToken(_, { code, phone_number }) {
      return await api.users.verifyOtpToken(code, phone_number);
    },
    setAuthToken({ commit }, token) {
      commit("setAuthToken", token);
    },
    setCurrentUser({ commit }, user) {
      commit("setUser", user);
      if (user.authToken) {
        commit("setAuthToken", user.authToken);
      }
    },
    async fetchUserProfile({ commit, state }, force = false) {
      // Try to get from cache first
      if (!force && state.user.id) {
        const cached = userCache.getFromCache(`user-${state.user.id}`);
        if (cached) {
          commit("setUser", cached);
          return cached;
        }
      }

      const response = await api.users.getProfile();
      if (!response || !response.id) {
        throw new Error("User profile response is missing ID");
      }
      commit("setUser", response);
      return response;
    },
    async updateUserProfile({ commit }, userData) {
      const response = await api.users.updateUser(userData);
      commit("setUser", response);

      // Clear cache after update
      if (response.id) {
        userCache.deleteFromCache(`user-${response.id}`);
      }

      return response;
    },
    async refreshUserProfile({ dispatch }) {
      return dispatch("fetchUserProfile", true);
    },
    logout({ commit }) {
      commit("clearAuth");
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.authToken,
    currentUser: (state) => state.user,
    userDetails: (state) => ({
      first_name: state.user.first_name || "",
      last_name: state.user.last_name || "",
      email: state.user.email || "",
    }),
  },
};
