export default {
  greeting: "Привіт, {name}!",
  email: {
    unverified: {
      title: "Email не підтверджено",
      message:
        "Підтвердіть свою електронну адресу для повного доступу до облікового запису",
    },
    verified: {
      title: "Email підтверджено",
      message: "Вашу електронну адресу підтверджено",
    },
  },
  sections: {
    venues: {
      title: "Ваші заклади",
      empty: "У вас ще немає закладів",
    },
    data: {
      title: "Дані облікового запису",
      phone: {
        note: "Номер телефону не можна змінити",
      },
    },
    levels: {
      title: "Рівні гри",
      description:
        "Визначте свій рівень у кожному виді спорту, щоб допомогти адміністрації та іншим гравцям знайти відповідних суперників. Це допоможе організувати кращі матчі та більш збалансовані ігри.",
      lastUpdate: "Останнє оновлення: {date}",
      levels: {
        0: "Початківець",
        1: "Базовий",
        2: "Середній",
        3: "Просунутий",
        4: "Дуже просунутий",
        5: "Напівпрофесіонал",
        6: "Професіонал",
      },
    },
  },
  form: {
    firstName: "Ім'я",
    lastName: "Прізвище",
    email: "Email",
    phone: "Номер телефону",
    buttons: {
      save: "Зберегти зміни",
      saving: "Збереження...",
    },
  },
  // Profile navigation
  navigation: {
    venues: {
      label: "Ваші заклади",
      icon: "🏢",
    },
    data: {
      label: "Дані облікового запису",
      icon: "👤",
    },
    levels: {
      label: "Рівні гри",
      icon: "📊",
    },
  },
};
