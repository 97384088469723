export default {
  greeting: "Hi, {name}!",
  email: {
    unverified: {
      title: "Email not verified",
      message: "Verify your email address to fully access your account",
    },
    verified: {
      title: "Email verified",
      message: "Your email address has been verified",
    },
  },
  sections: {
    venues: {
      title: "Your venues",
      empty: "You don't have any venues yet",
    },
    data: {
      title: "Account data",
      phone: {
        note: "Phone number cannot be changed",
      },
    },
    levels: {
      title: "Game levels",
      description:
        "Define your level in each sport to help reception and other players find suitable opponents. This will help organize better matches and more balanced games.",
      lastUpdate: "Last update: {date}",
      levels: {
        0: "Beginner",
        1: "Basic",
        2: "Intermediate",
        3: "Advanced",
        4: "Very advanced",
        5: "Semi-pro",
        6: "Professional",
      },
    },
  },
  form: {
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
    phone: "Phone number",
    buttons: {
      save: "Save changes",
      saving: "Saving...",
    },
  },
  // Profile navigation
  navigation: {
    venues: {
      label: "Your venues",
      icon: "🏢",
    },
    data: {
      label: "Account data",
      icon: "👤",
    },
    levels: {
      label: "Game levels",
      icon: "📊",
    },
  },
};
