export default {
  menu: {
    courts: {
      label: "Корти",
      icon: "🎾",
    },
    classes: {
      label: "Заняття",
      icon: "👥",
    },
    trainers: {
      label: "Тренери",
      icon: "👨‍🏫",
    },
    passes: {
      label: "Абонементи",
      icon: "🎫",
    },
  },
  meta: {
    courts: {
      title: "Бронювання кортів | Playmore",
      description:
        "Забронюйте тенісний, сквош або бадмінтонний корт. Перевірте доступність та ціни в спортивних закладах у вашому районі.",
    },
    classes: {
      title: "Фітнес-заняття | Playmore",
      description:
        "Запишіться на фітнес-заняття, йогу, пілатес та інші групові заняття. Перевірте розклад та наявність місць.",
    },
    trainers: {
      title: "Профіль тренера | Playmore",
      description:
        "Керуйте своїм профілем тренера, доступністю та бронюваннями. Перевірте майбутні заняття та тренування.",
    },
  },
  common: {
    loading: {
      classes: "Завантаження занять...",
      trainers: "Завантаження тренерів...",
      passes: "Завантаження абонементів...",
      data: "Завантаження даних...",
    },
    errors: {
      fetchClasses: "Не вдалося завантажити список занять",
      fetchTrainers: "Не вдалося завантажити список тренерів",
      fetchPasses: "Не вдалося завантажити список абонементів",
      fetchVenue: "Не вдалося завантажити дані закладу",
      availability: "Не вдалося перевірити доступність",
      createNotification: "Не вдалося створити сповіщення",
      noCourts: "Немає доступних кортів у вибраний час",
    },
    filters: {
      allTrainers: "Всі тренери",
      allClasses: "Всі заняття",
    },
    notifications: {
      notify: "Сповістити мене",
      willNotify: "Ми сповістимо вас",
      modal: {
        createTitle: "Створити сповіщення?",
        createMessage:
          "Бажаєте отримати сповіщення, коли цей час стане доступним?",
        successTitle: "Сповіщення створено!",
        successMessage: "Ми сповістимо вас, коли цей час стане доступним.",
      },
    },
  },
  classes: {
    header: {
      trainerTBA: "Тренера буде оголошено",
    },
    tile: {
      duration: "хв",
      participants: {
        occupied: "зайнято",
      },
    },
    payment: {
      required: "Потрібна оплата",
      timeLimit:
        "Щоб підтвердити бронювання, будь ласка, здійсніть оплату протягом 30 хвилин. Після цього часу бронювання буде автоматично скасовано.",
      methods: {
        title: "Спосіб оплати",
        onSite: "Оплата на місці",
        carnet: "Абонемент",
        online: "Онлайн-оплата",
      },
      online: {
        title: "Виберіть спосіб онлайн-оплати",
        blik: "BLIK",
        transfer: "Банківський переказ",
        paymentInitiation: "Банківський переказ",
      },
    },
    carnets: {
      title: "Ваші абонементи",
      remaining: "Залишилось",
      entries: "входів",
      amount: "PLN",
    },
    partnerCard: {
      question: "У вас є партнерська картка?",
      yes: "Так",
      no: "Ні",
    },
    price: {
      base: "Базова ціна",
      partnerCard: "Партнерська картка",
      serviceFee: {
        title: "Сервісний збір",
        tooltip:
          "Збір покриває операційні витрати, пов'язані з обробкою платежів, включаючи комісії операторів платежів, адміністративні витрати та обслуговування системи бронювання.",
      },
      total: "Загальна ціна",
    },
    buttons: {
      payment: "Перейти до оплати",
      close: "Закрити",
      cancel: "Скасувати",
      reserve: "Забронювати",
    },
    loading: "Завантаження занять...",
  },
  courts: {
    steps: {
      sport: {
        title: "Виберіть вид спорту",
        number: "1",
      },
      court: {
        title: "Виберіть тип корту",
        number: "2",
        select: "Виберіть корт",
      },
    },
    duration: {
      label: "Тривалість бронювання",
      options: {
        60: "60 хвилин",
        90: "90 хвилин",
        120: "120 хвилин",
      },
    },
    priceList: {
      title: "Виберіть прейскурант",
    },
    partnerCard: {
      title: "Кількість знижкових карток",
      discount: "Знижкові картки",
    },
  },
  passes: {
    details: {
      validity: "Термін дії",
      days: "днів",
      value: "Вартість",
      type: "Тип",
      types: {
        amount: "Кількісний",
        value: "Вартісний",
      },
      entries: "входів",
      remaining: "Залишилось",
      duration: "Тривалість",
      price: "Ціна",
      availableHours: "Доступні години",
    },
    actions: {
      buy: "Купити абонемент",
    },
    empty: "Немає доступних абонементів",
    legal: {
      text: "Розміщуючи замовлення, Користувач підтверджує, що ознайомився та приймає умови та політику конфіденційності обраного спортивного закладу, а також",
      terms: "умови надання послуг Playmore",
      fee: "(раніше Reservise.com). Згідно з прейскурантом, до кожної транзакції буде додано сервісний збір у розмірі 1.98 PLN брутто за обробку електронних платежів.",
    },
  },
  trainers: {
    details: {
      availability: "Доступність",
    },
  },
};
