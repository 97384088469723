<template>
  <div class="min-h-screen bg-white flex flex-col">
    <GlobalDebugBar v-if="isDevelopment" />
    <NavBar />
    <main class="w-full flex-grow">
      <router-view></router-view>
    </main>
    <!-- Footer - nie wyświetlaj na stronach logowania i rejestracji -->
    <footer
      v-if="!['Login', 'CompleteRegistration'].includes($route.name)"
      class="mt-8 border-t border-gray-100"
    >
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div
          class="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-4 sm:gap-0"
        >
          <!-- Lewa kolumna - logo i informacja -->
          <div
            class="flex items-center gap-3 text-gray-600 flex-wrap justify-center sm:justify-start"
          >
            <span class="text-sm">{{
              t("app.layout.footer.provider.text")
            }}</span>
            <img
              src="@/assets/Playmore-Logo-Default-SVG.svg"
              :alt="t('app.layout.header.logo.alt')"
              class="h-5 w-auto"
            />
            <span class="text-sm">{{
              t("app.layout.footer.provider.previousName")
            }}</span>
          </div>

          <!-- Prawa kolumna - informacje prawne -->
          <div class="text-xs text-gray-500 text-center sm:text-right">
            <p class="mb-2">
              {{ t("app.layout.footer.legal.terms.text") }}
              <a href="#" class="text-dynamic-orange hover:underline">
                {{ t("common.legal.terms") }}
              </a>
              {{ t("app.layout.footer.legal.privacy.text") }}
              <a href="#" class="text-dynamic-orange hover:underline">
                {{ t("common.legal.privacy") }}
              </a>
              {{ t("common.legal.company") }}.
            </p>
            <p>{{ t("common.legal.copyright", { year: currentYear }) }}</p>
          </div>
        </div>
      </div>
    </footer>
    <GlobalMessage v-if="show" :message="message" :type="type" />
  </div>
</template>

<script setup>
import { provide, computed, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import NavBar from "@/components/NavBar.vue";
import GlobalMessage from "@/components/GlobalMessage.vue";
import GlobalDebugBar from "@/components/GlobalDebugBar.vue";
import { useGlobalMessage } from "@/composables/useGlobalMessage";
import { useMetaTags } from "@/composables/useMetaTags";

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();
const { message, type, show, showMessage } = useGlobalMessage();
const { updateMetaTags } = useMetaTags();

// Aktualny rok do stopki
const currentYear = computed(() => new Date().getFullYear());

// Sprawdzenie środowiska
const isDevelopment = computed(() => process.env.NODE_ENV !== "production");

// Udostępnij funkcję showMessage dla wszystkich komponentów potomnych
provide("showMessage", showMessage);

// Inicjalizacja danych użytkownika
const initializeApp = async () => {
  // Sprawdź czy użytkownik jest zalogowany
  if (store.getters["user/isAuthenticated"]) {
    try {
      // Pobierz dane użytkownika z cache lub API
      await store.dispatch("user/fetchUserProfile");
    } catch (error) {
      console.error("Error initializing app:", error);
      // Jeśli nie udało się pobrać danych użytkownika, wyloguj go
      await store.dispatch("user/logout");
      router.push("/login");
    }
  }
};

// Odśwież dane użytkownika
const refreshUserData = async () => {
  if (store.getters["user/isAuthenticated"]) {
    try {
      await store.dispatch("user/refreshUserProfile");
    } catch (error) {
      console.error("Error refreshing user data:", error);
      showMessage("Nie udało się odświeżyć danych użytkownika", "error");
    }
  }
};

// Aktualizuj meta tagi przy zmianie trasy
watch(
  () => route.name,
  () => {
    const metaConfig = {
      Login: {
        title: t("login.welcome.title"),
        description: t("login.welcome.description"),
      },
      ReservationCourts: {
        title: t("meta.reservations.courts.title"),
        description: t("meta.reservations.courts.description"),
      },
      ReservationClasses: {
        title: t("meta.reservations.classes.title"),
        description: t("meta.reservations.classes.description"),
      },
      ReservationTrainers: {
        title: t("meta.reservations.trainers.title"),
        description: t("meta.reservations.trainers.description"),
      },
      Account: {
        title: t("meta.account.title"),
        description: t("meta.account.description"),
      },
    };

    const config = metaConfig[route.name] || {
      title: "Playmore",
      description: t("meta.app.description"),
    };

    updateMetaTags(config);
  },
  { immediate: true }
);

// Inicjalizacja przy montowaniu komponentu
onMounted(() => {
  initializeApp();
});

// Udostępnij funkcję odświeżania danych dla komponentów potomnych
provide("refreshUserData", refreshUserData);
</script>
