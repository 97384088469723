export default {
  noProfile: {
    title: "No active trainer profile",
    message: "You don't have a trainer profile in any venue yet.",
    instruction:
      "To activate your trainer profile, please contact the reception of your chosen club. After activation, you'll be able to manage your availability and accept reservations.",
  },
  status: {
    title: "Profile status",
    pendingMessage:
      "Your profile is pending venue approval. After approval, you'll be able to manage your availability.",
    acceptedMessage: "Your profile is active and approved by the venue.",
    visibility: {
      label: "Online visibility",
      visible: "Visible",
      hidden: "Hidden",
    },
    comment: "Comment",
  },
  workingHours: {
    title: "Working hours",
    save: "Save hours",
    saving: "Saving...",
    success: "Availability hours have been saved",
    error: "Failed to save availability hours",
  },
  reservations: {
    views: {
      calendar: "Calendar",
      list: "List",
    },
    filters: {
      upcoming: "Upcoming",
      past: "Past",
    },
    cancellation: {
      deadline: "Cancellation deadline",
    },
    list: {
      noReservations: "No reservations",
      client: "Client",
      date: "Date",
      time: "Time",
      venue: "Venue",
      status: "Status",
      actions: "Actions",
    },
    calendar: {
      previous: "Previous",
      next: "Next",
      today: "Today",
      month: "Month",
      week: "Week",
      day: "Day",
    },
  },
  navigation: {
    overview: {
      label: "Overview",
      icon: "👨‍🏫",
    },
    schedule: {
      label: "Working hours",
      icon: "📅",
    },
    reservations: {
      label: "My reservations",
      icon: "📋",
    },
  },
};
