export default {
  switchLanguage: "Змінити мову",
  languages: {
    polish: "Польська",
    english: "Англійська",
    ukrainian: "Українська",
  },
  loading: "Завантаження...",
  error: "Сталася помилка",
  buttons: {
    close: "Закрити",
    cancel: "Скасувати",
    continue: "Продовжити",
    back: "Назад",
    today: "Сьогодні",
    thisWeek: "Цей тиждень",
    clearFilters: "Очистити фільтри",
    confirm: "Підтвердити",
    reserve: "Забронювати",
    save: "Зберегти",
  },
  fields: {
    firstName: "Ім'я",
    lastName: "Прізвище",
    email: "Email",
    phone: "Телефон",
    password: "Пароль",
  },
  price: {
    base: "Базова ціна",
    total: "Загальна ціна",
    currency: "PLN",
  },
  payment: {
    methods: {
      title: "Спосіб оплати",
      onSite: "Оплата на місці",
      pass: "Абонемент",
      online: "Онлайн-оплата",
      blik: "BLIK",
      transfer: "Банківський переказ",
      paymentInitiation: "Банківський переказ",
      select: "Виберіть спосіб оплати",
    },
    serviceFee: {
      title: "Сервісний збір",
      amount: "1.98 PLN",
      tooltip:
        "Збір покриває операційні витрати, пов'язані з обробкою платежів, включаючи комісії операторів платежів, адміністративні витрати та обслуговування системи бронювання.",
    },
    status: {
      paid: "Оплачено",
      unpaid: "Не оплачено",
      pending: "Очікує оплати",
      pending_online: "Очікує онлайн-оплати",
    },
    required: "Потрібна оплата",
    timeLimit:
      "Щоб підтвердити бронювання, будь ласка, здійсніть оплату протягом 30 хвилин. Після цього часу бронювання буде автоматично скасовано.",
    proceedToPayment: "Перейти до оплати",
    processing: "Обробка...",
  },
  errors: {
    loadingFailed: "Не вдалося завантажити дані. Спробуйте ще раз пізніше.",
    savingFailed: "Не вдалося зберегти зміни. Спробуйте ще раз пізніше.",
    generalError: "Сталася помилка. Спробуйте ще раз пізніше.",
  },
  status: {
    pending: "В очікуванні",
    accepted: "Прийнято",
  },
  venue: {
    select: "Вибрати заклад",
    all: "Всі заклади",
    address: "Адреса закладу",
  },
  search: {
    placeholder: "Пошук...",
    byClient: "Пошук за клієнтом...",
    noResults: "Немає результатів",
    filters: "Фільтри",
  },
  date: {
    from: "Від",
    to: "До",
  },
  time: {
    from: "З",
    to: "До",
  },
  weekDays: {
    0: "Неділя",
    1: "Понеділок",
    2: "Вівторок",
    3: "Середа",
    4: "Четвер",
    5: "П'ятниця",
    6: "Субота",
  },
  form: {
    required: "Це поле обов'язкове",
    invalid: "Неправильне значення",
    save: {
      button: "Зберегти",
      loading: "Збереження...",
      success: "Успішно збережено",
      error: "Помилка при збереженні",
    },
  },
  legal: {
    terms: "умови надання послуг",
    privacy: "політика конфіденційності",
    gdpr: "GDPR",
    company: "Playmore",
    copyright: "© {year} Playmore. Всі права захищені.",
  },
};
