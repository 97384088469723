import { ref, readonly } from "vue";

const message = ref("");
const type = ref("info");
const show = ref(false);
let currentTimer = null;

export function useGlobalMessage() {
  const clearCurrentTimer = () => {
    if (currentTimer) {
      clearTimeout(currentTimer);
      currentTimer = null;
    }
  };

  const showMessage = (newMessage, newType = "info", duration = 3000) => {
    // Wyczyść poprzedni timer jeśli istnieje
    clearCurrentTimer();

    // Aktualizuj stan
    message.value = newMessage;
    type.value = newType;
    show.value = true;

    // Użyj requestAnimationFrame dla lepszej wydajności
    requestAnimationFrame(() => {
      currentTimer = setTimeout(() => {
        show.value = false;
        currentTimer = null;
      }, duration);
    });
  };

  const hideMessage = () => {
    clearCurrentTimer();
    show.value = false;
  };

  // Wyczyść timer przy odmontowaniu aplikacji
  if (typeof window !== "undefined") {
    window.addEventListener("beforeunload", clearCurrentTimer);
  }

  return {
    message: readonly(message),
    type: readonly(type),
    show: readonly(show),
    showMessage,
    hideMessage,
  };
}
