export default {
  // Proces logowania
  login: {
    title: {
      phone: "Zaloguj się",
      code: "Wpisz kod",
    },
    subtitle: {
      phone: "Podaj swój numer telefonu",
      code: "Wprowadź kod otrzymany w SMS",
    },
    form: {
      // Używa wspólnych pól z common.fields
      phone: {
        placeholder: "123 456 789",
      },
      code: {
        label: "Kod weryfikacyjny",
        placeholder: "Wpisz 4-cyfrowy kod",
      },
      buttons: {
        phone: "Wyślij kod",
        code: "Weryfikuj",
        loading: "Proszę czekać...",
      },
    },
    messages: {
      codeSent: "Kod weryfikacyjny został wysłany na podany numer telefonu.",
    },
  },
  // Proces rejestracji
  registration: {
    complete: {
      title: "Dokończ rejestrację",
      subtitle:
        "Uzupełnij swoje dane, aby w pełni korzystać z możliwości platformy",
      form: {
        // Używa wspólnych pól z common.fields
        buttons: {
          submit: "Zapisz i przejdź dalej",
        },
      },
      welcome: {
        title: "Witaj w Playmore!",
        description:
          "Uzupełnij swój profil, aby w pełni korzystać z możliwości platformy. Twoje dane pomogą nam lepiej dopasować ofertę do Twoich potrzeb.",
        features: {
          notifications: {
            title: "Powiadomienia o rezerwacjach",
            description:
              "Otrzymuj potwierdzenia i przypomnienia na swój adres email",
          },
          history: {
            title: "Historia aktywności",
            description: "Śledź swoje rezerwacje i płatności w jednym miejscu",
          },
        },
      },
    },
    confirm: {
      title: "Potwierdź adres email",
      loading: "Trwa potwierdzanie Twojego adresu email...",
      success:
        "Adres email został pomyślnie potwierdzony. Przekierowujemy Cię na stronę główną...",
    },
  },
  // Informacje prawne
  legal: {
    consent:
      'Klikając "Wyślij kod" zgadzasz się na warunki usługi Playmore (dawniej Reservise), oznajmiasz że zapoznałeś się i akceptujesz',
    gdpr: {
      button: "RODO",
      title: "Zgodnie z RODO masz prawo do:",
      rights: [
        "dostępu do swoich danych osobowych",
        "sprostowania (poprawiania) swoich danych",
        "usunięcia danych",
        "ograniczenia przetwarzania danych",
        "przenoszenia danych",
        "wniesienia sprzeciwu wobec przetwarzania",
        "cofnięcia zgody w dowolnym momencie",
        "wniesienia skargi do organu nadzorczego",
      ],
      info: "Administratorem Twoich danych osobowych jest Reservise Sp. z o.o. ul. Lindleya 16/301 • 02-013 Warszawa • KRS: 0000522272. Dane są przetwarzane w celu świadczenia usług rezerwacji obiektów sportowych oraz w celach marketingowych, na podstawie Twojej zgody lub uzasadnionego interesu administratora.",
    },
  },
  // Sekcja powitalna
  welcome: {
    title: "Reservise to teraz Playmore!",
    description:
      "Dołącz do społeczności ponad 500 000 graczy i odkryj nowy wymiar rezerwacji obiektów sportowych. Wszystko czego potrzebujesz w jednym miejscu.",
    features: {
      reservations: {
        title: "Łatwe rezerwacje",
        description:
          "Rezerwuj korty, zajęcia i obiekty sportowe w kilka sekund",
      },
      venues: {
        title: "Największa baza obiektów",
        description: "Setki obiektów sportowych w całej Polsce",
      },
      trainers: {
        title: "Profesjonalni trenerzy",
        description:
          "Znajdź trenera i zapisz się na zajęcia indywidualne lub grupowe",
      },
      passes: {
        title: "Wygodne karnety",
        description: "Kupuj i zarządzaj karnetami w jednym miejscu",
      },
      account: {
        title: "Wszystko w jednym koncie",
        description:
          "Zarządzaj rezerwacjami, karnetami i zajęciami z poziomu jednej aplikacji",
      },
    },
  },
};
