export default {
  app: {
    title: "Playmore - Sports Facility Booking",
    description:
      "Playmore - platform for booking sports facilities, fitness classes and personal training sessions",
  },
  reservations: {
    courts: {
      title: "Court Booking | Playmore",
      description:
        "Book tennis, squash or badminton courts. Check availability and prices at sports facilities in your area.",
    },
    classes: {
      title: "Fitness Classes | Playmore",
      description:
        "Sign up for fitness classes, yoga, pilates and other group activities. Check schedule and availability.",
    },
    trainers: {
      title: "Personal Trainers | Playmore",
      description:
        "Find a personal trainer and schedule individual training sessions. Browse trainer profiles and their specializations.",
    },
  },
  account: {
    title: "Your Profile | Playmore",
    description:
      "Manage your account, bookings and passes. Check activity history and upcoming classes.",
  },
  venue: {
    title: "{name} | Playmore",
    description:
      "{name} - check sports facility availability, pricing and book online.",
  },
  trainer: {
    title: "{name} - Trainer | Playmore",
    description:
      "Trainer {name} - check availability, specializations and book personal training sessions.",
  },
};
