<template>
  <transition name="fade">
    <div
      v-if="show"
      :class="[
        'fixed bottom-4 right-4 p-4 rounded-md shadow-md',
        typeClass,
        'dark:shadow-black/20',
      ]"
    >
      {{ message }}
    </div>
  </transition>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";

export default {
  name: "GlobalMessage",
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "info",
      validator: (value) =>
        ["success", "error", "info", "warning"].includes(value),
    },
    duration: {
      type: Number,
      default: 3000,
    },
  },
  setup(props) {
    const show = ref(true);
    let timer = null;

    const typeClass = computed(() => {
      switch (props.type) {
        case "success":
          return "bg-green-500 text-white dark:bg-dark-messages-success-bg dark:text-dark-messages-success-text";
        case "error":
          return "bg-red-500 text-white dark:bg-dark-messages-error-bg dark:text-dark-messages-error-text";
        case "warning":
          return "bg-yellow-500 text-white dark:bg-dark-messages-warning-bg dark:text-dark-messages-warning-text";
        default:
          return "bg-blue-500 text-white dark:bg-dark-messages-info-bg dark:text-dark-messages-info-text";
      }
    });

    onMounted(() => {
      // Używamy requestAnimationFrame dla lepszej wydajności
      requestAnimationFrame(() => {
        timer = setTimeout(() => {
          show.value = false;
        }, props.duration);
      });
    });

    onBeforeUnmount(() => {
      // Czyścimy timer przy odmontowaniu komponentu
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    });

    return {
      show,
      typeClass,
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
