export default {
  404: {
    title: "Out! Ball out of court!",
    description:
      "Looks like this page took a break. Shall we play on another court?",
    button: "Back to main court",
    easterEgg: "Wow! Nice rally! Want to become our coach? 🎾",
  },
  500: {
    title: "Oops! Server needs a technical timeout",
    description:
      "Our (tennis) server needs a quick fix. Our magic racquet is already working on it!",
    button: "Try again",
    easterEgg: "I see you like fixing things! Want to join our team? 🛠️",
  },
};
