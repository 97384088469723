<template>
  <div>
    <!-- Główny kontener -->
    <div class="fixed top-0 left-0 right-0 z-50 font-mono">
      <!-- Główny pasek -->
      <div
        class="bg-gradient-to-r from-amber-500 to-amber-400 text-black px-4 py-2 flex items-center justify-between shadow-lg"
      >
        <div class="flex items-center gap-8">
          <!-- Status -->
          <div
            class="flex items-center gap-2 bg-white/20 backdrop-blur-sm px-3 py-1 rounded-full shadow-sm"
          >
            <span
              class="w-2 h-2 rounded-full bg-green-500 animate-pulse shadow-sm"
            ></span>
            <span class="font-medium tracking-wide">DEV</span>
          </div>

          <!-- Podstawowe info -->
          <div class="flex items-center gap-6">
            <div
              class="flex items-center gap-2 bg-white/20 backdrop-blur-sm px-3 py-1 rounded-full shadow-sm"
            >
              <span class="text-black/50">ver</span>
              <span class="font-medium">{{ appVersion }}</span>
            </div>
            <div
              class="flex items-center gap-2 bg-white/20 backdrop-blur-sm px-3 py-1 rounded-full shadow-sm"
            >
              <span class="text-black/50">env</span>
              <span class="font-medium">{{ nodeEnv }}</span>
            </div>
          </div>

          <!-- System info -->
          <div
            class="flex items-center gap-2 bg-white/20 backdrop-blur-sm px-3 py-1 rounded-full shadow-sm"
          >
            <span class="text-black/70">{{ browserInfo }}</span>
          </div>

          <!-- FPS Counter -->
          <div
            class="flex items-center gap-2 bg-white/20 backdrop-blur-sm px-3 py-1 rounded-full shadow-sm"
          >
            <span class="text-black/50">fps</span>
            <span class="font-medium">{{ fps }}</span>
          </div>
        </div>

        <!-- Przycisk rozwijania -->
        <button
          @click="isCollapsed = !isCollapsed"
          class="bg-white/20 backdrop-blur-sm w-8 h-8 flex items-center justify-center rounded-full hover:bg-white/30 transition-all duration-200 shadow-sm hover:shadow active:scale-95"
        >
          {{ isCollapsed ? "▼" : "▲" }}
        </button>
      </div>

      <!-- Dodatkowe szczegóły -->
      <div
        v-if="!isCollapsed"
        class="bg-gradient-to-r from-amber-400/95 to-amber-300/95 text-black px-4 py-3 border-t border-white/20 shadow-lg"
      >
        <div class="grid grid-cols-4 gap-4">
          <!-- Build Info -->
          <div class="bg-white/20 backdrop-blur-sm p-3 rounded-lg shadow-sm">
            <h3
              class="font-medium mb-2 text-xs uppercase tracking-wider text-black/60"
            >
              Build Info
            </h3>
            <div class="space-y-1.5 text-sm">
              <div class="flex justify-between items-center">
                <span class="text-black/50">Mode</span>
                <span
                  class="font-medium px-2 py-0.5 bg-white/30 rounded-full text-xs"
                  >{{ buildMode }}</span
                >
              </div>
              <div class="flex justify-between items-center">
                <span class="text-black/50">Time</span>
                <span
                  class="font-medium px-2 py-0.5 bg-white/30 rounded-full text-xs"
                  >{{ formattedBuildTime }}</span
                >
              </div>
              <div class="flex justify-between items-center">
                <span class="text-black/50">Vue</span>
                <span
                  class="font-medium px-2 py-0.5 bg-white/30 rounded-full text-xs"
                  >{{ vueVersion }}</span
                >
              </div>
            </div>
          </div>

          <!-- Performance -->
          <div class="bg-white/20 backdrop-blur-sm p-3 rounded-lg shadow-sm">
            <h3
              class="font-medium mb-2 text-xs uppercase tracking-wider text-black/60"
            >
              Performance
            </h3>
            <div class="space-y-1.5 text-sm">
              <div class="flex justify-between items-center">
                <span class="text-black/50">Memory</span>
                <span
                  class="font-medium px-2 py-0.5 bg-white/30 rounded-full text-xs"
                  >{{ memoryUsage }}</span
                >
              </div>
              <div class="flex justify-between items-center">
                <span class="text-black/50">Page Load</span>
                <span
                  class="font-medium px-2 py-0.5 bg-white/30 rounded-full text-xs"
                  >{{ pageLoadTime }}ms</span
                >
              </div>
              <div class="flex justify-between items-center">
                <span class="text-black/50">Resources</span>
                <span
                  class="font-medium px-2 py-0.5 bg-white/30 rounded-full text-xs"
                  >{{ resourceCount }}</span
                >
              </div>
            </div>
          </div>

          <!-- System -->
          <div class="bg-white/20 backdrop-blur-sm p-3 rounded-lg shadow-sm">
            <h3
              class="font-medium mb-2 text-xs uppercase tracking-wider text-black/60"
            >
              System
            </h3>
            <div class="space-y-1.5 text-sm">
              <div class="flex justify-between items-center">
                <span class="text-black/50">Platform</span>
                <span
                  class="font-medium px-2 py-0.5 bg-white/30 rounded-full text-xs"
                  >{{ platformInfo }}</span
                >
              </div>
              <div class="flex justify-between items-center">
                <span class="text-black/50">Language</span>
                <span
                  class="font-medium px-2 py-0.5 bg-white/30 rounded-full text-xs"
                  >{{ languageInfo }}</span
                >
              </div>
              <div class="flex justify-between items-center">
                <span class="text-black/50">Screen</span>
                <span
                  class="font-medium px-2 py-0.5 bg-white/30 rounded-full text-xs"
                  >{{ screenInfo }}</span
                >
              </div>
            </div>
          </div>

          <!-- Network -->
          <div class="bg-white/20 backdrop-blur-sm p-3 rounded-lg shadow-sm">
            <h3
              class="font-medium mb-2 text-xs uppercase tracking-wider text-black/60"
            >
              Network
            </h3>
            <div class="space-y-1.5 text-sm">
              <div class="flex justify-between items-center">
                <span class="text-black/50">Status</span>
                <span
                  class="font-medium px-2 py-0.5 bg-white/30 rounded-full text-xs"
                  >{{ networkStatus }}</span
                >
              </div>
              <div class="flex justify-between items-center">
                <span class="text-black/50">Type</span>
                <span
                  class="font-medium px-2 py-0.5 bg-white/30 rounded-full text-xs"
                  >{{ connectionType }}</span
                >
              </div>
              <div class="flex justify-between items-center">
                <span class="text-black/50">Speed</span>
                <span
                  class="font-medium px-2 py-0.5 bg-white/30 rounded-full text-xs"
                  >{{ connectionSpeed }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Spacer -->
    <div :class="{ 'h-[48px]': isCollapsed, 'h-[200px]': !isCollapsed }" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";

const isCollapsed = ref(true);
const browserInfo = ref("");
const pageLoadTime = ref(0);
const resourceCount = ref(0);
const memoryUsage = ref("N/A");
const fps = ref(0);
const lastFrameTime = ref(performance.now());
const frameCount = ref(0);

// System info refs
const platformInfo = ref("N/A");
const languageInfo = ref("N/A");
const screenInfo = ref("N/A");

// Network info refs
const networkStatus = ref("online");
const connectionType = ref("N/A");
const connectionSpeed = ref("N/A");

// Pobierz wersję z package.json
const appVersion = ref(process.env.VUE_APP_VERSION || "0.0.1");

// Computed properties dla zmiennych środowiskowych
const buildMode = computed(() => process.env.BUILD_MODE || "development");
const nodeEnv = computed(() => process.env.NODE_ENV);
const vueVersion = computed(() => process.env.VUE_APP_VERSION);

// Formatowanie czasu builda
const formattedBuildTime = computed(() => {
  const buildTime = process.env.BUILD_TIME;
  if (!buildTime) return new Date().toLocaleString();
  return new Date(buildTime).toLocaleTimeString();
});

// Formatowanie pamięci
const formatMemory = (bytes) => {
  if (!bytes) return "N/A";
  const mb = bytes / 1024 / 1024;
  return `${Math.round(mb * 100) / 100} MB`;
};

// FPS Counter
const updateFPS = () => {
  const now = performance.now();
  frameCount.value++;

  if (now - lastFrameTime.value >= 1000) {
    fps.value = Math.round(
      (frameCount.value * 1000) / (now - lastFrameTime.value)
    );
    frameCount.value = 0;
    lastFrameTime.value = now;
  }

  requestAnimationFrame(updateFPS);
};

// Aktualizacja metryk wydajności
const updatePerformanceMetrics = () => {
  // Pamięć (tylko Chrome)
  if (window.performance && window.performance.memory) {
    memoryUsage.value = formatMemory(window.performance.memory.usedJSHeapSize);
  }

  // Czas ładowania strony
  if (window.performance) {
    pageLoadTime.value = Math.round(performance.now());
    resourceCount.value = performance.getEntriesByType("resource").length;
  }
};

// Aktualizacja informacji systemowych
const updateSystemInfo = () => {
  // Platform
  platformInfo.value = navigator?.platform || "N/A";

  // Language
  languageInfo.value = navigator?.language || "N/A";

  // Screen
  if (window.screen) {
    screenInfo.value = `${window.screen.width}x${window.screen.height}`;
  }
};

// Aktualizacja informacji o sieci
const updateNetworkInfo = () => {
  networkStatus.value = navigator.onLine ? "online" : "offline";

  if (navigator.connection) {
    connectionType.value = navigator.connection.effectiveType || "N/A";
    connectionSpeed.value = navigator.connection.downlink
      ? `${navigator.connection.downlink} Mbps`
      : "N/A";
  }
};

// Event listeners dla sieci
const handleOnline = () => (networkStatus.value = "online");
const handleOffline = () => (networkStatus.value = "offline");

onMounted(() => {
  try {
    // Pobierz informacje o przeglądarce
    const ua = navigator.userAgent;
    const browser =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
    browserInfo.value = `${browser[1]} ${browser[2]}`;

    // Inicjalizacja wszystkich metryk
    updatePerformanceMetrics();
    updateSystemInfo();
    updateNetworkInfo();
    updateFPS();

    // Event listeners
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    if (navigator.connection) {
      navigator.connection.addEventListener("change", updateNetworkInfo);
    }

    // Aktualizuj metryki co sekundę
    const metricsInterval = setInterval(updatePerformanceMetrics, 1000);

    // Cleanup
    onUnmounted(() => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      if (navigator.connection) {
        navigator.connection.removeEventListener("change", updateNetworkInfo);
      }
      clearInterval(metricsInterval);
    });
  } catch (error) {
    console.error("Error initializing debug bar:", error);
  }
});
</script>
